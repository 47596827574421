import React from 'react';
import styled from 'styled-components';
import useAppData from '@/hooks/useAppData';
import img3 from '../../assets/images/知多点logo.png';
import Footer from '../../components/common/Footer';
import { useNavigate } from 'react-router-dom';
const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 10px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin: 0;
`;

const Details = styled.p`
  font-size: 0.9rem;
  margin: 0;
  color: #d1ecf1;
`;


const DownloadButton = styled.button`
  background-color: #ff6f61;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: auto;

  &:hover {
    background-color: #de6262;
  }
`;
const Section = styled.div`
  margin: 20px 0;
  
  text-align: center;
`;
const FixedHeader = () => {
  const { appData, handleDownload } = useAppData();
   // const { appData, handleDownload } = useAppData();
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };
console.log(appData);
  return (
    <HeaderContainer>
      {appData ? (
        <>
          <Logo src={img3} alt="Logo" />
          <InfoContainer>
            <Title>知多点</Title>
            <Details>应用介绍及详细信息</Details>
          </InfoContainer>
          <DownloadButton onClick={() => handleDownload(appData.downloadUrl, appData.size)}>
            下载App
          </DownloadButton>
        </>
      ):(<>
          <Section>
              <p>功能介绍：<a href='#'  onClick={() => handleNavigation('/')}>查看详情</a></p>
              <p>用户协议：<a href='#'  onClick={() => handleNavigation('/user-agreement')}>详细规则</a></p>
              <p>App隐私政策：<a href='#' onClick={() => handleNavigation('/privacy-policy')}>查看详情</a></p>
            </Section>
      <Footer/>
      
      </>)}
    </HeaderContainer>
  );
};

export default FixedHeader;
