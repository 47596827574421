import React from 'react';
import FeatureCard from './FeatureCard';
import './Features.css';
import img1 from '../../assets/images/首页.jpg';
import img2 from '../../assets/images/发现页.jpg';
import img3 from '../../assets/images/ai问答页面.jpg';
const Features = () => {
  const features = [
    {
      imgSrc: img1,
      title: '创新点一',
      description: 'APP提供智能推荐功能，帮助你在深入学习本领域知识的同时，探索相关领域，拓宽视野。你可以一键收藏内容，方便日后回顾。内置AI助理，快速解答疑问，并推荐相关问题，让提问更轻松。集成强大搜索功能，让你更高效地找到所需信息。',
    },
    {
      imgSrc: img2,
      title: '创新点二',
      description: 'APP汇集各行业精华知识，直击主题，内容精炼，助你快速掌握行业基础，轻松上手学习。简洁高效的内容展示，让你更方便获取关键知识。立即下载，开启你的高效学习之旅！',
    },
    {
      imgSrc: img3,
      title: '创新点三',
      description: '注册即送400次AI问答，每日签到再得10次，分享更可获取1000次AI问答次数，帮助你轻松解决各种问题。无论在哪个页面，都能一键询问AI，享受更加全面的知识支持。',
    },
     
    // Add more features as needed
  ];

  return (
    <div className="features">
      {features.map((feature, index) => (
        <FeatureCard
          key={index}
          imgSrc={feature.imgSrc}
          title={feature.title}
          description={feature.description}
        />
      ))}
    </div>
  );
};

export default Features;
