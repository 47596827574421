import React from 'react';
import './MessageDetail.css';

const MessageDetail = ({ message, onBackClick }) => {
    if (!message) {
        return <div className="message-detail">请选择一条消息查看详情。</div>;
    }

    return (
        <div className="message-detail">
         
            <h2>{message.title}</h2>
            <div className="message-content">
                <p>{message.content}</p>
            </div>
               {onBackClick && (
                <button className="back-button" onClick={onBackClick}>
                    返回
                </button>
            )}
        </div>
    );
};

export default MessageDetail;
