import React, { useState } from 'react';
import CustomBackButton from './CustomBackButton';
import CustomTextField from './CustomTextField';
import CustomButton from './CustomButton';
import CustomAlertDialog from './CustomAlertDialog';
import CustomAlert from './CustomAlert';
import './ContactUsPage.css';

const ContactUsPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogPlatform, setDialogPlatform] = useState('');

  const handleSubmit = () => {
    if (name.trim() === '') {
      setAlert({ message: '姓名不能为空', backgroundColor: 'red', textColor: 'white' });
      return;
    } else if (name.trim().length > 10) {
      setAlert({ message: '姓名字数不能超过10个字', backgroundColor: 'red', textColor: 'white' });
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.trim() === '') {
      setAlert({ message: '电子邮件不能为空', backgroundColor: 'red', textColor: 'white' });
      return;
    } else if (!emailRegex.test(email)) {
      setAlert({ message: '电子邮件格式不正确', backgroundColor: 'red', textColor: 'white' });
      return;
    }

    if (message.trim() === '') {
      setAlert({ message: '信息内容不能为空', backgroundColor: 'red', textColor: 'white' });
      return;
    } else if (message.trim().length > 100) {
      setAlert({ message: '信息内容字数不能超过100个字', backgroundColor: 'red', textColor: 'white' });
      return;
    }

    setAlert({ message: '提交成功', backgroundColor: 'green', textColor: 'white' });
    setName('');
    setEmail('');
    setMessage('');
  };

  const handleDialogOpen = (platform) => {
    setDialogPlatform(platform);
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleAlertClose = () => {
    setAlert(null);
  };

  return (
    <div className="contact-us-page">
      <div className="app-bar">
        <CustomBackButton />
        <div onClick={() => handleDialogOpen('QQ')}>QQ</div>
        <div onClick={() => handleDialogOpen('微信')}>微信</div>
      </div>
      <div className="form-container">
        {alert && (
          <div className="alert-wrapper">
            <CustomAlert
              message={alert.message}
              backgroundColor={alert.backgroundColor}
              textColor={alert.textColor}
              onClose={handleAlertClose}
            />
          </div>
        )}
        <div className="text-field-wrapper">
          <CustomTextField
          label="姓名"
            controller={{ value: name, setValue: setName }}
            hintText="请输入你的姓名"
          />
        </div>
        <div className="text-field-wrapper">
          <CustomTextField
             label="邮箱"
            controller={{ value: email, setValue: setEmail }}
            hintText="请输入你的有效邮箱"
          />
        </div>
        <div className="text-field-wrapper">
         
          <CustomTextField
            label="信息"
            controller={{ value: message, setValue: setMessage }}
            hintText="请输入您的信息"
            isMultiline={true}
          />
        </div>
        <div className="button-wrapper">
          <CustomButton
            text="提交"
            backgroundColor="yellow"
            textColor="black"
            onClick={handleSubmit}
          />
        </div>
      </div>
      {showDialog && (
        <div className="dialog-overlay" onClick={handleDialogClose}>
          <div className="dialog-container" onClick={(e) => e.stopPropagation()}>
            <CustomAlertDialog
              imageUrl={dialogPlatform === '微信' ? 'https://43.138.136.178:8800/api/files/downloadFile/bug反馈微信群.png' : 'https://43.138.136.178:8800/api/files/downloadFile/bug反馈qq群.png'}
              message={<p>长按图片保存到手机</p>}
              onImageLongPress={() => alert('保存图片')}
              onClose={handleDialogClose}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUsPage;
