import React from 'react';

const CustomTextField = ({ controller, hintText, isMultiline, label = "默认提示文本" }) => {
  return (
    <div style={{ width: '100%', marginBottom: '20px' }}>
      <label style={styles.label}>{label}</label>
      {isMultiline ? (
        <textarea
          value={controller.value}
          onChange={(e) => controller.setValue(e.target.value)}
          placeholder={hintText}
          style={styles.textField}
        />
      ) : (
        <input
          type="text"
          value={controller.value}
          onChange={(e) => controller.setValue(e.target.value)}
          placeholder={hintText}
          style={styles.textField}
        />
      )}
    </div>
  );
};

const styles = {
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  textField: {
    width: '100%',
    padding: '10px',
    borderRadius: '20px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
};

export default CustomTextField;
