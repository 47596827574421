import React from 'react';
import AppInfo from './AppInfo';
import './App.css';

const MainInfo = () => {
  return (
    <div className="App">
      <AppInfo />
    </div>
  );
}

export default MainInfo;
