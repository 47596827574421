import light from './light';
import dark from './dark';

const themes = {
  light,
  dark,
};

export const getTheme = (theme) => {
  return themes[theme] || themes.light;
};
