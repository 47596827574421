import React from 'react';

const CategoryItems = ({ items }) => {
  return (
    <div className="category-items">
      {items.map((item, index) => (
        <div key={index} className="category-item">
          <img src={item.collectionsUrl} alt={item.name} />
          <p>{item.name}</p>
        </div>
      ))}
    </div>
  );
};

export default CategoryItems;
