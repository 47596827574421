import React from 'react';
import './MessageItem.css';

const MessageItem = ({ message, onClick }) => {
    return (
        <div className="message-item" onClick={() => onClick(message)}>
            <h3>{message.title}</h3>
            <p>{message.content}</p>
        </div>
    );
};

export default MessageItem;
