import React from 'react';
import MessageItem from './MessageItem';
import './MessageList.css';

const MessageList = ({ messages, onMessageClick }) => {
    return (
        <div className="message-list">
            {messages.map((message) => (
                <MessageItem key={message.id} message={message} onClick={onMessageClick} />
            ))}
        </div>
    );
};

export default MessageList;
