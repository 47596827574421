;
let dark;
dark={
  background: '#333333',
  color: '#ffffff',
  button: {
    background: '#555555',
    color: '#ffffff'
  }
}
export default dark;