import React from 'react';
import Features from './Features';
import CustomCarousel from './Carousel';
import './app.module.css';

const AppFeature = () => {
  return (
    <div className="app">
      <div className="container">
      <CustomCarousel/>
        <Features />
      </div>   
    </div>
  );
};

export default AppFeature;

