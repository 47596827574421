import React, { useEffect } from 'react';

const CustomAlert = ({ message, backgroundColor, textColor, duration = 3000, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div style={{ ...styles.alert, backgroundColor, color: textColor }}>
      {message}
    </div>
  );
};

const styles = {
  alert: {
    padding: '10px',
    borderRadius: '20px',
    margin: '10px 0',
    textAlign: 'center',
  },
};

export default CustomAlert;
