import React, { useState, useEffect } from 'react';
import MessageList from './MessageList';
import MessageDetail from './MessageDetail';
import './MessagesPage.css';

const MessagesPage = () => {
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const messages = [
        { id: 1, title: '消息1', content: '这是消息1的内容。' },
        { id: 2, title: '消息2', content: '这是消息2的内容。' },
        { id: 3, title: '消息3', content: '这是消息3的内容。' },
        { id: 4, title: '消息4', content: '这是消息4的内容。qqqqqqqqq111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111' },
        { id: 5, title: '消息5', content: '这是消息5的内容。' },
        { id: 6, title: '消息6', content: '这是消息6的内容。' },
        { id: 7, title: '消息7', content: '这是消息7的内容。' },
        { id: 8, title: '消息8', content: '这是消息8的内容。' },
        { id: 9, title: '消息9', content: '这是消息9的内容。' },
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleBackClick = () => {
        setShowDetail(false);
        setSelectedMessage(null);
    };

    const handleMessageClick = (message) => {
        setSelectedMessage(message);
        if (isMobile) {
            setShowDetail(true);
        }
    };

    return (
        <div className="messages-page">
            <div className={`content ${showDetail && isMobile ? 'show-detail' : ''}`}>
                {!showDetail || !isMobile ? (
                    <MessageList messages={messages} onMessageClick={handleMessageClick} />
                ) : null}
                {showDetail && isMobile ? (
                    <MessageDetail message={selectedMessage} onBackClick={handleBackClick} />
                ) : (
                    <MessageDetail message={selectedMessage} />
                )}
            </div>
        </div>
    );
};

export default MessagesPage;

