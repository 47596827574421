import React, { useState, useEffect } from 'react';
import TabBar from './TabBar';
import CategoryItems from './CategoryItems';
import './DiscoveryApp.css';

const DiscoveryApp = () => {
  const [tabs] = useState([
    { categoryId: 1, name: '科学' },
    { categoryId: 2, name: '艺术' },
    { categoryId: 3, name: '物理' },
    { categoryId: 4, name: '计算机' },
    { categoryId: 5, name: '数学' },

    // Add more tabs as needed
  ]);

  const [items] = useState([
    {
      id: 1,
      categoryId: 1,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 1',
    },
    {
      id: 2,
      categoryId: 1,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 2',
    },
        {
      id: 1,
      categoryId: 1,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 1',
    },
    {
      id: 2,
      categoryId: 1,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 2',
    },

        {
      id: 1,
      categoryId: 1,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 1',
    },
    {
      id: 2,
      categoryId: 1,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 2',
    },
        {
      id: 1,
      categoryId: 1,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 1',
    },
    {
      id: 2,
      categoryId: 1,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 2',
    },
        {
      id: 1,
      categoryId: 1,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 1',
    },
    {
      id: 2,
      categoryId: 1,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 2',
    },
        {
      id: 1,
      categoryId: 1,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 1',
    },
    {
      id: 2,
      categoryId: 1,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 2',
    },
    {
      id: 3,
      categoryId: 2,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 3',
    },
    {
      id: 4,
      categoryId: 3,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 4',
    },
      {
      id: 3,
      categoryId: 3,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 3',
    },
    {
      id: 4,
      categoryId: 2,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 4',
    },
      {
      id: 3,
      categoryId: 4,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 3',
    },
    {
      id: 4,
      categoryId: 4,
      collectionsUrl: 'https://via.placeholder.com/150',
      name: 'Item 4',
    },
    // Add more items as needed
  ]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="app">
      <TabBar tabs={tabs} selectedIndex={selectedIndex} onSelect={setSelectedIndex} />
      <div className="content">
        <CategoryItems items={items.filter(item => item.categoryId === tabs[selectedIndex].categoryId)} />
      </div>
    </div>
  );
};

export default DiscoveryApp;
