import React, { useState, useEffect } from 'react';
import Tab from './Tab';

const TabBar = ({ tabs, selectedIndex, onSelect }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`tab-bar ${isMobile ? 'mobile' : 'desktop'}`}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          name={tab.name}
          isActive={selectedIndex === index}
          onClick={() => onSelect(index)}
        />
      ))}
    </div>
  );
};

export default TabBar;
