
// import React from 'react';
// import './App.css';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { I18nProvider } from './contexts/I18nContext';
// import { ThemeProvider } from './contexts/ThemeContext';
// import AppFeature from '@/pages/AppFeatures/AppFeature';
// import Header from '@/components/common/Header';
// import MainContent from '@/pages/UserAgreement/MainContent';
// import PrivacyPolicy from '@/pages/UserAgreement/PrivacyPolicy';
// import Footer from '@/components/common/Footer';
// import MainInfo from '@/pages/AppInfo/mainInfo';
// import MainContentWrapper from './MainContentWrapper'; // 引入包装组件
// import MessagesPage from '@/pages/Message/MessagesPage';
// import DiscoveryApp from '@/pages/Discovery/DiscoveryApp';
// import ContactUsPage from '@/pages/ContectUs/ContactUsPage';
// import 'bootstrap/dist/css/bootstrap.min.css';

// class App extends React.Component {
//   componentDidMount() {
//     const renderTime = performance.now();
//     console.log(`首屏渲染时间: ${renderTime.toFixed(2)}毫秒`);
//   }

//   render() {
//     return (
//       <Router>
//         <div>
//           <I18nProvider>
//             <ThemeProvider>
//               <Routes>
//                 <Route 
//                   path="/main-info" 
//                   element={<MainInfo />} 
//                 />
//                 <Route 
//                   path="*" 
//                   element={
//                     <>
//                       <Header />
//                       <MainContentWrapper>
//                         <Routes>
//                           <Route path="/" element={<AppFeature />} />
//                           <Route path="/user-agreement" element={<MainContent />} />
//                           <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//                           <Route path="/messages" element={<MessagesPage />} />
//                           <Route path="/discovery" element={<DiscoveryApp/>} />
//                           <Route path="/contactus" element={<ContactUsPage/>}/>
//                         </Routes>
//                       </MainContentWrapper>
//                       <Footer />
//                     </>
//                   } 
//                 />
//               </Routes>
//             </ThemeProvider>
//           </I18nProvider>
//         </div>
//       </Router>
//     );
//   }
// }

// export default App;
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { I18nProvider } from './contexts/I18nContext';
import { ThemeProvider } from './contexts/ThemeContext';
import AppFeature from '@/pages/AppFeatures/AppFeature';
import Header from '@/components/common/Header';
import MainContent from '@/pages/UserAgreement/MainContent';
import PrivacyPolicy from '@/pages/UserAgreement/PrivacyPolicy';
import Footer from '@/components/common/Footer';
import MainInfo from '@/pages/AppInfo/mainInfo';
import MainContentWrapper from './MainContentWrapper';
import MessagesPage from '@/pages/Message/MessagesPage';
import DiscoveryApp from '@/pages/Discovery/DiscoveryApp';
import ContactUsPage from '@/pages/ContectUs/ContactUsPage';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends React.Component {
  componentDidMount() {
    const renderTime = performance.now();
    console.log(`首屏渲染时间: ${renderTime.toFixed(2)}毫秒`);
  }

  render() {
    return (
      <Router>
        <div>
          <Helmet>
            <title>知多点APP - 高效学习之旅</title>
            <meta name="description" content="知多点app提供智能推荐功能，帮助你在深入学习本领域知识的同时，探索相关领域，拓宽视野。你可以一键收藏内容，方便日后回顾。内置AI助理，快速解答疑问，并推荐相关问题，让提问更轻松。集成强大搜索功能，让你更高效地找到所需信息。" />
            <meta name="keywords" content="知多点APP, AI助理, 智能推荐, 高效学习, 知识支持, 行业知识, 学习平台, 注册赠送, AI问答, 问答功能" />
            <meta property="og:title" content="知多点APP - 高效学习之旅" />
            <meta property="og:description" content="知多点APP汇集各行业精华知识，直击主题，内容精炼，助你快速掌握行业基础，轻松上手学习。简洁高效的内容展示，让你更方便获取关键知识。立即下载，开启你的高效学习之旅！" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.nie2004.cn" />
           
          </Helmet>
          <I18nProvider>
            <ThemeProvider>
              <Routes>
                <Route 
                  path="/main-info" 
                  element={<MainInfo />} 
                />
                <Route 
                  path="*" 
                  element={
                    <>
                      <Header />
                      <MainContentWrapper>
                        <Routes>
                          <Route path="/" element={<AppFeature />} />
                          <Route path="/user-agreement" element={<MainContent />} />
                          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                          <Route path="/messages" element={<MessagesPage />} />
                          <Route path="/discovery" element={<DiscoveryApp/>} />
                          <Route path="/contactus" element={<ContactUsPage/>}/>
                        </Routes>
                      </MainContentWrapper>
                      <Footer />
                    </>
                  } 
                />
              </Routes>
            </ThemeProvider>
          </I18nProvider>
        </div>
      </Router>
    );
  }
}

export default App;
