import React from 'react';

const CustomButton = ({ text, backgroundColor, textColor, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{ ...styles.button, backgroundColor, color: textColor }}
    >
      {text}
    </button>
  );
};

const styles = {
  button: {
    width: '100%',
    border: 'none',
    borderRadius: '20px',
    fontSize: '16px',
    cursor: 'pointer',
    padding: '10px',
    boxSizing: 'border-box',
  },
};

export default CustomButton;
