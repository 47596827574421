import React from 'react';

const CustomBackButton = () => {
  const goBack = () => {
    window.history.back();
  };

  return <button onClick={goBack} style={styles.backButton}>Back</button>;
};

const styles = {
  backButton: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
  },
};

export default CustomBackButton;
