import React, { createContext, useState, useEffect } from 'react';
import { getTheme } from '../themes';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const [themeStyles, setThemeStyles] = useState(getTheme('light'));

  useEffect(() => {
    setThemeStyles(getTheme(theme));
  }, [theme]);

  const switchTheme = (themeName) => {
    setTheme(themeName);
  };
console.log(themeStyles);
  return (
    <ThemeContext.Provider value={{ themeStyles, switchTheme, theme }}>
      {children}
    </ThemeContext.Provider>
  );
};
