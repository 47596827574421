import React, { useState } from 'react';
import './Footer.css';

const Footer = () => {
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownload = () => {
        if (!isDownloading) {
            setIsDownloading(true);

            // 设置下载链接
            const link = document.createElement('a');
            link.href = 'http://124.71.26.18/%E7%9F%A5%E5%A4%9A%E7%82%B9v1.0.3.apk';
            link.download = '知多点v1.0.3.apk';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // 模拟下载完成后将 `isDownloading` 设置为 `false`
            setTimeout(() => {
                setIsDownloading(false);
            }, 3000);  // 设置下载按钮冷却时间为 3 秒
        }
    };

    return (
        <footer className="footer">
            <div className="desktop-footer">
                <p>&copy; 2024 知多点 All rights reserved.</p>
            </div>
            <div className="mobile-footer">
                <button 
                    className="download-button" 
                    onClick={handleDownload} 
                    disabled={isDownloading}
                >
                    {isDownloading ? "下载中..." : "下载"}<span className="highlight">知多点APP</span>体验更多精彩内容
                </button>
            </div>
        </footer>
    );
};

export default Footer;
