import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import FixedHeader from './FixedHeader';
import useAppData from '../../hooks/useAppData';
import img3 from '../../assets/images/知多点logo.png';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 100px; /* Add margin to avoid overlap with fixed header */
`;

const Logo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 15px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 10px 0;
`;

const Version = styled.p`
  font-size: 1rem;
  color: #666;
  margin: 5px 0;
`;

const UpdateDate = styled.p`
  font-size: 1rem;
  color: #666;
  margin: 5px 0;
`;

const DownloadLink = styled.a`
  font-size: 1rem;
  color: #007bff;
  margin: 15px 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Section = styled.div`
  margin: 20px 0;
`;

const Button = styled.button`
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 40px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 20px;
width: 100%;
  &:hover {
    background-color: #333;
  }
`;

const AppInfo = () => {
  const { appData, handleDownload } = useAppData();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <FixedHeader />
      <Container>
        {appData && (
          <>
            <Logo src={img3} alt="App Logo" />
            <Title>知多点</Title>
            <Version>版本：{appData.version}</Version>
            <UpdateDate>更新日期：{appData.updatedAt.split('T')[0]}</UpdateDate>
            <DownloadLink href="#">—— 下载 App 查看更多 ——</DownloadLink>
         <Section>
              <p>功能介绍：<a href='#'  onClick={() => handleNavigation('/')}>查看详情</a></p>
              <p>用户协议：<a href='#'  onClick={() => handleNavigation('/user-agreement')}>详细规则</a></p>
              <p>App隐私政策：<a href='#' onClick={() => handleNavigation('/privacy-policy')}>查看详情</a></p>
            </Section>
            <p>开发者：NXY</p>
            <Button onClick={() => handleDownload(appData.downloadUrl, appData.size)}>
              下载知多点App，解锁更多精彩
            </Button>
          </>
        )}
      </Container>
    </>
  );
};

export default AppInfo;
