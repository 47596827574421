import React from 'react';
import './Tab.css';
const Tab = ({ name, isActive, onClick }) => {
  return (
    <div className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}>
      {name}
    </div>
  );
};

export default Tab;
