
import { useState, useEffect } from 'react';

const useAppData = () => {
  const [appData, setAppData] = useState(null);

  useEffect(() => {
    const cachedData = getCachedAppData();
    if (cachedData) {
      setAppData(cachedData);
    } else {
      fetchAppData();
    }
  }, []);

  const isAppDownloaded = () => {
    return localStorage.getItem('isAppDownloaded') === 'true';
  };

  const handleDownload = (downloadUrl, size) => {
    if (isAppDownloaded()) {
      const userChoice = window.confirm('您已经下载了此应用程序。是否要重新下载？');
      if (userChoice) {
        window.location.href = downloadUrl;
        localStorage.setItem('isAppDownloaded', 'true');
      }
    } else {
      const userChoiceDownloaded = window.confirm(`当前应用大小为 ${size}。是否下载？`);
      if (userChoiceDownloaded) {
        window.location.href = downloadUrl;
        localStorage.setItem('isAppDownloaded', 'true');
      }
    }
  };

  const cacheAppData = (data) => {
    const cache = {
      data: data,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem('appDataCache', JSON.stringify(cache));
  };

  const getCachedAppData = () => {
    const cache = localStorage.getItem('appDataCache');
    if (cache) {
      const parsedCache = JSON.parse(cache);
      const now = new Date().getTime();
      const cacheDuration = 1 * 60 * 60 * 1000; // 24小时缓存有效期
      if (now - parsedCache.timestamp < cacheDuration) {
        return parsedCache.data;
      } else {
        localStorage.removeItem('appDataCache');
      }
    }
    return null;
  };

  const fetchAppData = () => {
    fetch('https://43.138.136.178:8800/api/versions/latest')
      .then((response) => {
        console.log(response);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Log the response text for debugging
        return response.text().then((text) => {
          console.log('Response text:', text);
          try {
            return JSON.parse(text);
          } catch (e) {
            throw new Error('Invalid JSON response');
          }
        });
      })
      .then((result) => {
        console.log('Parsed result:', result);
        if (result.code === 200 && result.state) {
          cacheAppData(result.data);
          setAppData(result.data);
        } else {
          console.error('获取应用信息失败');
        }
      })
      .catch((error) => console.error('请求失败:', error));
  };

  return { appData, handleDownload };
};

export default useAppData;
