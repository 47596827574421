import React, { useEffect } from 'react';

const CustomAlertDialog = ({ imageUrl, message, onImageLongPress, duration = 6000, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const handleLongPress = (e) => {
    e.preventDefault();
    onImageLongPress();
  };

  return (
    <div style={styles.dialog}>
      <img
        src={imageUrl}
        alt="Alert"
        style={styles.image}
        onContextMenu={handleLongPress}
      />
      <div>{message}</div>
    </div>
  );
};

const styles = {
  dialog: {
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '20px',
    textAlign: 'center',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  image: {
    maxWidth: '100%',
    borderRadius: '8px',
    marginBottom: '10px',
  },
};

export default CustomAlertDialog;
