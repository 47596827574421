// eslint-disable-next-line import/no-anonymous-default-export
;
let light;
light={
  background: '#ffffff',
  color: '#000000',
  button: {
    background: '#007bff',
    color: '#ffffff'
  }
}

export default light;