import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Carousel.css'; // Import the custom CSS file
const CustomCarousel = () => {
    return (
        <Carousel>
            <Carousel.Item>
                <img
                    className="d-block w-100 custom-carousel-img"
                    src="https://img2.baidu.com/it/u=1028011339,1319212411&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=313"
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 custom-carousel-img"
                    src="https://img1.baidu.com/it/u=728576857,3157099301&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=313"
                    alt="Second slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 custom-carousel-img"
                    src="https://img0.baidu.com/it/u=3643028167,149007168&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500"
                    alt="Third slide"
                />
            </Carousel.Item>
        </Carousel>
    );
};

export default CustomCarousel;
