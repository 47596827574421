import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from './SearchIcon';
import './Header.css';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [showTitle, setShowTitle] = useState(true);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleSearchClick = (isSearchOpen) => {
        if (window.innerWidth <= 768) {
            setShowTitle(!isSearchOpen);
        }
        else{
            setShowTitle(true);
        }
    };

    return (
        <div className="header">
            <h1 style={{ color: 'red', textAlign: 'center' }}>{showTitle ? "知多点" : ""}</h1>
            <div className="menu-icon" style={{ color: 'red', zIndex: 9999 }} onClick={toggleMenu}>
                &#9776;
            </div>
            <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
                <Link to="/" onClick={toggleMenu}>产品功能</Link>
                <Link to="/user-agreement" onClick={toggleMenu}>隐私政策</Link>
                <Link to="/privacy-policy" onClick={toggleMenu}>用户协议</Link>
                <Link to="/main-info" onClick={toggleMenu}>app下载</Link>
                <Link to="/discovery" onClick={toggleMenu}>发现</Link>
                  <Link to="/contactus" onClick={toggleMenu}>联系我们</Link>
            </nav>
            <div className="icons">
                <SearchIcon onSearchClick={handleSearchClick} />
                <Link to="/messages" className="notification-icon">
                    🔔
                </Link>
            </div>
        </div>
    );
};

export default Header;
